<template>
  <div id="profile-page">
    <!-- My Details -->
    <vx-card class="mt-base">
      <ul class="page-suggestions-list">
        <li class="page-suggestion flex items-center mb-4">
          <div class="mr-3">

          </div>
          <div class="leading-tight">
            <p class="font-medium">My Details</p>
            <span class="text-xs">Manage account details</span>
          </div>
          <div class="ml-auto">
            <div class="flex">
              <vs-button style="margin-right: 10px;" @click="changePasswordHandler">Change Password</vs-button>



            </div>
          </div>
        </li>
      </ul>

    </vx-card>

    <vx-card class="mt-base">
          <div class="leading-tight">
            <p class="font-medium">My Account Details</p>
          </div>
          <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">
      <vs-input class="w-full mt-4" label="First Name" v-model="firstName" v-validate="'required'" name="firstName" data-vv-as="first name" />
        
        <span class="text-danger text-sm"  v-show="errors.has('firstName')">{{ errors.first('firstName') }}</span>

        <vs-input class="w-full mt-4" label="Last Name" v-model="lastName" v-validate="'required'" name="lastName" data-vv-as="last name"/>
        <span class="text-danger text-sm"  v-show="errors.has('lastName')">{{ errors.first('lastName') }}</span>

        <vs-input class="w-full mt-4"  label="Email" v-model="email" type="email" v-validate="'required|email'" name="email" data-vv-as="email"/>
        <span class="text-danger text-sm"   v-show="errors.has('email')">{{ errors.first('email') }}</span>
      </div>
      </div>
      <br>
          <div>
              <vs-button style="margin-right: 10px;" @click="save_changes">Save</vs-button>
          </div>
    </vx-card>


    <!-- Profile Detail -->

  </div>
</template>

<script>
import { mapActions } from 'vuex';

    export default {
      name: "Profile",
        
      data() {
        return {
          id: '',
          firstName: '',
          lastName: '',
          email: ''
        }
      },
      methods: {
        ...mapActions('tutor', ['fetchTutorById','updateTutorById']),
        changePasswordHandler() {
          this.$router.push("/admin/change-password");
        },
        
        getTutorDetails() {
          let self = this
          let userInfo = localStorage.getItem('userInfo');
          console.log(JSON.parse(userInfo)._id)
          this.fetchTutorById(JSON.parse(userInfo)._id).then(res => {
            self.firstName = res.data.data.firstName
            self.lastName = res.data.data.lastName
            self.email = res.data.data.email
          })
        },
    async save_changes() {
      let isValid = await this.$validator.validateAll()
      
      if(!isValid){
        return false
      }
      
      let self = this
      let userInfo = localStorage.getItem('userInfo');
      let tutorId = JSON.parse(userInfo)._id
      
      let tutorData = {
          firstName: self.firstName,
          lastName: self.lastName,
          fullName: self.firstName+' '+self.lastName,
          email: self.email,
          _id: tutorId
      }

      this.updateTutorById({tutorId, tutorData}).then(res => {
        console.log(res.data.data)
        // this.$store.commit('tutor/SET_USER_ID', res.data.data._id)
        // this.$router.replace({ name: 'EditTutorDetails', params: { tutorId: res.data.data._id } })
        this.$vs.notify({
          title: 'Update',
          text: res.data.message,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success'
        })
      }).catch(err => {
        this.$vs.notify({
          title: 'Failure',
          text: 'Something went wrong.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    },
    },
    
  async mounted() {
      await this.getTutorDetails()
  }
    }
</script>

<style scoped>

</style>
